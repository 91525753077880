import * as videokubeUtils from 'videokube-front-library/dist/videokubeUtils';
import { checkUsernameForNumbersFromCountry } from 'videokube-front-library/dist/videokubeUtils';


export const getKeyWithPrefixIfExistant = videokubeUtils.getKeyWithPrefixIfExistant;

export const removeParamsFromParamString = (paramsAsString: string, paramsToRemove: string[]) => {
    const urlParams = new URLSearchParams(paramsAsString);
    const msisdn = urlParams.get("mhe");
    const seenHe = urlParams.get("seen");
    const msisdnEncrypted = urlParams.get("msisdnEncrypted");
  
    if (!msisdn && !msisdnEncrypted) {
      return;
    }
  
    paramsToRemove.forEach((p) => {
      urlParams.delete(p);
    });
  
    if (msisdn && checkUsernameForNumbersFromCountry(msisdn)) {
      localStorage.setItem("msisdn", msisdn);
    }
  
    if (msisdnEncrypted) {
      localStorage.setItem("msisdn", msisdnEncrypted);
    }
  
    if (!seenHe) {
      let url = urlParams.toString() ? "?seen=1&" + urlParams.toString() : "?seen=1";
      window.location.href = window.location.origin + window.location.pathname + url;
    }
  };
